import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from "react-router-dom"

import '../../../../assets/styles/page/_choice.scss'
import imgChoice from '../../../../assets/images/icons/choice-product.svg'
import {ChoiceTypeInterface} from "../../../../domain/ChoiceType/ChoiceType"
import {useAppSelector} from "../../../store/hook"
import HeaderPage from "../../component/HeaderPage/HeaderPage"

const types: ChoiceTypeInterface[] = [
  { label: 'capital', url: 'url.distribution.session.add-session', isEnabled: false },
  { label: 'dividend', url: 'url.distribution.session.add-session', isEnabled: true },
  { label: 'add_value', url: 'url.distribution.session.add-session', isEnabled: false }
]

const ChoiceType: FunctionComponent = () => {
  const {t} = useTranslation()
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  return (
    <>
      <main
        className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''} main-content--sidebar-info`}>
        <div className="sidebar-info__main">
          <HeaderPage title={t('distribution.session.choice-type')} />
          <div className="commissioning-choice__wrapper">
            <p>{t('commissioning.add.select-modele')}</p>
            <div className="commissioning-choice--col-2">
              {types.map((type) => {
                return (
                  <Link key={t(type.url).toString()}
                        to={type.isEnabled ? `/${t('url.distribution.session.add-setting')}/${type.label}` : '#'}
                        className={`button button--choice button--choice--outline ${type.isEnabled ? '' : 'button--disabled'}`}
                        aria-disabled={type.isEnabled ? 'false' : 'true'}
                  >
                    {t('distribution.session.'+type.label)}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
        <div className="sidebar-info">
          <div className="sidebar-info__text">
            {t('distribution.session.choice-type')}
          </div>
          <div>
            <img src={imgChoice} alt="" />
          </div>
        </div>
      </main>
    </>
  )
}

export default ChoiceType
