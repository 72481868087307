import {Presenter} from '../Presenter'
import { SessionInvestorListInterface } from '../../domain/Distribution/SessionInvestorList'

class SessionInvestorListPresenter extends Presenter {

  private useCase: {
    retrieveSessionInvestorList: { data: SessionInvestorListInterface[] | null, nb_element: number }
  }

  constructor(retrieveSessionInvestorList: any) {
    super({
      viewModel: {
        title: 'distribution.session.investor.search',
        heading: [
          {name: 'distribution.session.investor.table.heading.product-code'},
          {name: 'distribution.session.investor.table.heading.product-name'},
          {name: 'distribution.session.investor.table.heading.product-type'},
          {name: 'distribution.session.investor.table.heading.product-nature'},
          {name: 'distribution.session.investor.table.heading.product-term'},
          {name: 'distribution.session.investor.table.heading.product-iban'},
          {name: 'distribution.session.investor.table.heading.investor-code'},
          {name: 'distribution.session.investor.table.heading.investor-subscriber'},
          {name: 'distribution.session.investor.table.heading.investor-co-subscriber'},
          {name: 'distribution.session.investor.table.heading.partner-code'},
          {name: 'distribution.session.investor.table.heading.partner-name'},
          {name: 'distribution.session.investor.table.heading.partner-nb-parts'},
        ],
        data: [],
        filtersShortcut: [
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keyword', 'type': 'input'},
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    })

    this.useCase = {
      retrieveSessionInvestorList,
    }
  }

  async load() {
    try {
      this._setSessions(await this.useCase.retrieveSessionInvestorList)
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setSessions(distributions: { data: SessionInvestorListInterface[] | null, nb_element: number }) {
    this.update({
      data: distributions.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': distributions.nb_element,
      }
    })
  }
}

export default SessionInvestorListPresenter
