import Caller from '../../domain/Api/Caller'
import envVariable from '../../infrastructure/presentation/util/envVariable'
import DistributionSessionGatewayInterface from '../../domain/Distribution/SessionGatewayInterface'
import {FilterSessionInterface, SessionListInterface, FilterSessionInvestorInterface, SessionInvestorListInterface, SessionInterface, ISettingForm, ActionList, TSessionProducts} from '../../domain/Distribution'
import dayjs from 'dayjs'
import {AmountDistributedInterface} from '../../domain/Distribution/AmountDistributed'

export default class DistributionSessionGateway implements DistributionSessionGatewayInterface {
  async getList(
    currentPage: number,
    itemsPerPage: number,
    filter: FilterSessionInterface | null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: SessionListInterface[] | null, nb_element: number } | null> {
    try {
      const data = await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/distribution/sessions?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
          keyword: filter?.keyword || '',
        },
        signal
      )
      if (!data) {
        return {
          data: [],
          nb_element: 0
        }
      }

      return data
    } catch (e) {
      return {
        data: [],
        nb_element: 0
      }
    }
  }

  async getInvestorList(
    currentPage: number,
    itemsPerPage: number,
    filter: FilterSessionInterface | null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: SessionInvestorListInterface[] | null, nb_element: number } | null> {
    try {
      return {
        data: [
          {
            id: '1',
            productCode: 'productCode1',
            productName: 'productName1',
            productType: 'productType1',
            productNature: 'productNature1',
            productTerm: 'productTerm1',
            productIban: 'productIban1',
            investorCode: 'investorCode1',
            investorSubscriber: 'investorSubscriber1',
            investorCoSubscriber: 'investorCoSubscriber1',
            partnerCode: 'partnerCode1',
            partnerName: 'partnerName1',
            partnerNbParts: 'partnerNbParts1',
          },
          {
            id: '2',
            productCode: 'productCode2',
            productName: 'productName2',
            productType: 'productType2',
            productNature: 'productNature2',
            productTerm: 'productTerm2',
            productIban: 'productIban2',
            investorCode: 'investorCode2',
            investorSubscriber: 'investorSubscriber2',
            investorCoSubscriber: 'investorCoSubscriber2',
            partnerCode: 'partnerCode2',
            partnerName: 'partnerName2',
            partnerNbParts: 'partnerNbParts2',
          }
        ],
        nb_element: 0
      }
      // const data = await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/distribution/sessions/investors?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
      //     keyword: filter?.keyword || '',
      //   },
      //   signal
      // )
      // if (!data) {
      //   return {
      //     data: [],
      //     nb_element: 0
      //   }
      // }
      //
      // return data
    } catch (e) {
      return {
        data: [],
        nb_element: 0
      }
    }
  }

  async investorListExport(sessionId: string, filter: FilterSessionInvestorInterface | null): Promise<Blob | null> {
    const data = await Caller.executeGetAsBlob(`${envVariable('REACT_APP_API_URL')}/distribution/sessions/${sessionId}/investor/extract`, {
      'keyword': filter?.keyword || '',
    })

    if (data instanceof Blob) {
      return data
    }

    return null
  }

  get(sessionId: string): Promise<SessionInterface | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/distribution/sessions/${sessionId}`, {})
      .then(data => {
        if (data) {
          data.subscriptionAt = dayjs(data.subscriptionAt, 'DD/MM/YYYY').toDate()
          data.year = dayjs(data.year, 'YYYY').toDate()
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }

  create(session: ISettingForm, type: string): Promise<SessionInterface> {
    const params = {
      type: type,
      month: session.sessionCriteria.month,
      year: dayjs(session.sessionCriteria.year).format('YYYY'),
      amounts: Object.values(session.sessionProducts).map((product: Partial<TSessionProducts>) => ({
        productId: product.productId,
        financialAmount: product.financialAmount,
        propertyAmount: product.propertyAmount,
      }))
    }

    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/distribution/sessions`, params
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    })
  }

  update(session: ISettingForm, type: string, uuid: string): Promise<SessionInterface> {
    const params = {
      type: type,
      month: session.sessionCriteria.month,
      year: dayjs(session.sessionCriteria.year).format('YYYY'),
      amounts: Object.values(session.sessionProducts).map((product: Partial<TSessionProducts>) => ({
        productId: product.productId,
        financialAmount: product.financialAmount,
        propertyAmount: product.propertyAmount,
      }))
    }

    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/distribution/sessions/${uuid}`, {...params}).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    })
  }

  getActions(uuid: string): Promise<ActionList | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/distribution/sessions/${uuid}/actions`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }

  setAction(uuid: string, actionSlug: string, date: string, reason: string | null): Promise<ActionList | null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/distribution/sessions/${uuid}/actions/${actionSlug}`, {
      'date': date,
      'reason': reason
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
  }
}
