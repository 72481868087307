import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm, SubmitHandler} from 'react-hook-form'
import { v4 as uuidV4 } from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setCountTotal} from '../../../../store/component/recurrence'
import {setOpenDatalistFilterDistributionSession, setOpenDatalistFilterDistributionSessionInvestor} from '../../../../store/component/event'
import { SessionInvestorListInterface, FilterSessionInvestorInterface } from '../../../../../domain/Distribution'
import {ListRequest} from '../../../../../useCase/Distribution/Session/Investor/ListRequest'
import ListUseCase from '../../../../../useCase/Distribution/Session/Investor/ListUseCase'
import Pagination from '../../Pagination/Pagination'

import iconHeadingSearch from '../../../../../assets/images/icons/datalist-heading-search.svg'
import '../../../../../assets/styles/components/_datalist.scss'
import {optionsNbRows} from "../../../../../fixtures/Referentiel"
import SelectCustom from "../../Elements/Select"
import {saveNbRowsInLocalStorage} from "../../../util/SavePreferencesInLocalStorage"
import {SortInterface, SortOrder} from "../../../../../domain/Utils/List"
import TableHead from "../../Table/TableHead"
import SessionInvestorListPresenter from '../../../../../presenter/Distribution/SessionInvestorListPresenter'
import SessionGateway from '../../../../../gateway/Distribution/SessionGateway'
import {ViewModelInterface} from '../../../../type'
import DistributionSessionGateway from '../../../../../gateway/Distribution/SessionGateway'
import downloadBlobFile from '../../../util/DownloadBlobFile'
import HeaderRight from '../Element/HeaderRight'

type Props = {
  sessionId: string
}

const InvestorDatalist: FunctionComponent<Props>= ({sessionId}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openDatalistFilterDistributionSessionInvestor = useAppSelector((state) => state.event.openDatalistFilterDistributionSessionInvestor)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)

  const { register, handleSubmit, setValue, watch } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    dispatch(setOpenDatalistFilterDistributionSessionInvestor({
      show: false,
      count: 0,
      filters: {
        keyword: '',
      }
    }))
  }, [])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterDistributionSessionInvestor.filters)
      const sessions = new ListUseCase(new SessionGateway()).execute(listRequest, controller.signal).then(response => {
        return response
      });
      const presenter = new SessionInvestorListPresenter(sessions);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })
      setValue('keyword', openDatalistFilterDistributionSessionInvestor.filters.keyword)
    }
  }, [currentPage, openDatalistFilterDistributionSessionInvestor.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterDistributionSessionInvestor.filters])

  const onSubmit: SubmitHandler<FilterSessionInvestorInterface> = data => {
    dispatch(setOpenDatalistFilterDistributionSession({
        show: false,
        count: openDatalistFilterDistributionSessionInvestor.count,
        filters: {
          keyword: data.keyword,
        }
      }
    ))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const resetFilters = () => {
    dispatch(setOpenDatalistFilterDistributionSession( {
      show: false,
      count: 0,
      filters: {
        keyword: '',
      }
    }))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new DistributionSessionGateway().investorListExport(sessionId, openDatalistFilterDistributionSessionInvestor.filters).then(response => {
      if (response) {
        downloadBlobFile(response, t('export.distributions-investors'))
      }
    }).finally(() => setLoadingExport(false))
  }

  return (<>
      {(viewModel !== null &&
        <>
          <div className={`datalist`}>
            <div className="datalist__title">{t(viewModel.title)}</div>
            <div className="datalist__header flex">
              <form onSubmit={handleSubmit(onSubmit)} className="w-full flex justify-between items-center">
                <div className="filter__input">
                  {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                    <div key={uuidV4()} className="input-no-border">
                      <img src={iconHeadingSearch} alt="" />
                      <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                    </div>
                  ))}
                </div>
                <div className="filter__actions flex items-center justify-end">
                  <button type="submit" className="button button--submit">{t('search.submit')}</button>
                  <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
                </div>
              </form>
              <HeaderRight numberOfActivatedFilters={0}
                           handleClickFilter={null}
                           handleClickExport={handleClickExport}
                           isLoadingExport={isLoadingExport}
                           allowExport={true}
                           hideFilter
              />
            </div>
            <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                          name="numberRows"
                          label={t('filters.display-results-by')}
                          options={optionsNbRows}
                          register={register}
                          noChoiceOption
            />
            <div className="table-fix-head">
              <table className="datalist__datas">
                <thead>
                {viewModel.heading && <TableHead typeFilter={"API"}
                                                 heading={viewModel.heading}
                                                 sortOrder={sortOrder}
                                                 setSortOrder={setSortOrder}
                                                 viewModel={viewModel}
                                                 setViewModel={setViewModel}
                                                 filter={openDatalistFilterDistributionSessionInvestor.filters}
                                                 watchNumberRows={watchNumberRows}
                                                 currentPage={currentPage}
                                                 listRequest={ListRequest}
                                                 listUseCase={ListUseCase}
                                                 listPresenter={SessionInvestorListPresenter}
                                                 gateway={SessionGateway}
                />}
                </thead>
                <tbody>
                {viewModel?.data?.map((item: SessionInvestorListInterface) => (
                  <tr key={uuidV4()}>
                    {item !== null && item !== undefined &&
                      <>
                        <td>
                          <div className={`badge badge--min badge--without-cta badge--partner-product u-mrs u-mbs`}>
                            {item.productCode}
                          </div>
                        </td>
                        <td>{item.productName}</td>
                        <td>{item.productType}</td>
                        <td>{item.productNature}</td>
                        <td>{item.productTerm}</td>
                        <td>{item.productIban}</td>
                        <td>{item.investorCode}</td>
                        <td>{item.investorSubscriber}</td>
                        <td>{item.investorCoSubscriber}</td>
                        <td>{item.partnerCode}</td>
                        <td>{item.partnerName}</td>
                        <td>{item.partnerNbParts}</td>
                      </>
                    }
                  </tr>
                ))}
                {viewModel?.data?.length === 0 &&
                  <tr>
                    <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                  </tr>
                }
                </tbody>
              </table>
            </div>
          </div>
          <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
        </>
      )}
    </>
  )
}

export default InvestorDatalist
