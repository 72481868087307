import { FilterSessionInvestorInterface } from "../../../../domain/Distribution/SessionInvestorList"

interface ListRequestInterface {
  currentPage: number
  itemPerPage: number
  filter: FilterSessionInvestorInterface|null
}

class ListRequest implements ListRequestInterface{
  public currentPage: number
  public itemPerPage: number
  public filter: FilterSessionInvestorInterface|null

  constructor(
    currentPage: number,
    itemPerPage: number,
    filter: FilterSessionInvestorInterface|null,
  ) {
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
    this.filter = filter
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }
