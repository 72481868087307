import { createSlice } from '@reduxjs/toolkit'

import {Contact} from '../../../domain/Partner/Contact'
import {Address} from '../../../domain/Partner/Address'
import {BeneficialBeneficiaries} from '../../../domain/Partner/BeneficialBeneficiaries'
import {ConventionAnnex} from '../../../domain/Partner/ConventionAnnex'
import {RelationLink} from '../../../domain/Partner/RelationLink'
import {Document, DocumentToSend} from '../../../domain/Partner/Document/Document'
import {DocumentProspect} from '../../../domain/Prospect/Document/Document'
import {DocumentCustomer} from '../../../domain/Customer/Document/Document'
import {DocumentProduct} from '../../../domain/Product/Document/Document'
import {BankInformation} from '../../../domain/Prospect/BankInformation'
import {Relation} from '../../../domain/Prospect/Relation'
import {BeneficialOwner} from '../../../domain/Prospect/BeneficialOwner'
import {MoralLegalRepresentative} from '../../../domain/Prospect/MoralLegalRepresentative'
import {FilterPartnerInterface} from '../../../domain/Partner/PartnerList'
import {FilterCustomerInterface} from '../../../domain/Customer/CustomerList'
import {FilterPreSubscriptionInterface} from '../../../domain/PreSubscription/PreSubscriptionList'
import {FilterMovementInterface} from '../../../domain/Movement/MovementList'
import {FilterRecurrenceInterface} from '../../../domain/Movement/RecurrenceList'
import {FilterProspectInterface} from '../../../domain/Prospect/ProspectList'
import {FilterProductInterface} from '../../../domain/Product/ProductList'
import {ProductBankInformation} from  "../../../domain/Product/BankInformation"
import {FilterDismenbermentInterface} from "../../../domain/Movement/Dismemberment/DismenbermentList";
import {Logo} from "../../../domain/Settings/GeneralSettings/Logo/Logo";
import {PledgeOrganizationInterface} from "../../../domain/Pledge/PledgeOrganization";
import {PledgeInterface} from "../../../domain/Pledge/Pledge";
import {DocumentInterface} from "../../../domain/Document/Document";
import {DocumentInterface as GedDocumentInterface} from "../../../domain/Document/DocumentInterface";

import {ProspectLegalRepresentatives} from "../../../domain/Prospect/ProspectLegalRepresentatives";
import {CustomerLegalRepresentatives} from "../../../domain/Customer/CustomerLegalRepresentatives";
import {PartnerInterface} from "../../../domain/Partner/Partner";
import { FilterPortfolioInterface } from '../../../domain/Movement/PortfolioList';
import { FilterUserInterface, UserListInterface } from '../../../domain/User/UserList';
import {Undivided} from "../../../domain/Prospect/Undivided";
import {
  CommissioningSessionInterface,
  FilterCommissioningInterface
} from "../../../domain/Commissioning/CommissioningList";
import {FilterPledgeInterface} from "../../../domain/Pledge/PledgeList";
import {City} from "../../../domain/Referentiel/City/City";
import DocumentGatewayInterface from "../../../domain/Document/DocumentGatewayInterface";
import { Country } from '../../../domain/Referentiel/Country/Country';
import {Transfer} from "../../../domain/Partner/Transfer";
import {Scale} from "../../../domain/Product/Scale";
import {ScaleItem} from "../../../domain/Product/ScaleItem";
import {FilterCommissioningSessionInterface} from "../../../domain/Commissioning/CommissioningSessionList";
import {GeneralAddressInterface} from "../../../domain/Address/GeneralAddress";
import {ICommissionCheckListDetail} from "../../presentation/component/Card/Comissioning/CommissionCheckList";
import { FilterRecurrenceMandateInterface } from '../../../domain/Recurrence/RecurrenceMandateList'
import {FilterRecurrenceSessionInterface} from '../../../domain/Recurrence/RecurrenceSessionList'
import {FilterRecurrenceSessionMandateInterface} from '../../../domain/Recurrence/RecurrenceSessionMandateInterface'
import {FilterSessionInterface as FilterDistributionSessionInterface} from '../../../domain/Distribution/SessionList'
import {FilterSessionInvestorInterface} from '../../../domain/Distribution/SessionInvestorList'

interface openPartnerContactForm {
  show: boolean,
  contact: Contact|null
}

interface openAddressForm {
  show: boolean,
  address: Address|null,
  defaultCountry: Country|null
}

interface openPartnerBeneficialBeneficiariesForm {
  show: boolean,
  beneficialBeneficiaries: BeneficialBeneficiaries|null
}

interface openPartnerConventionAnnexForm {
  show: boolean,
  partner: PartnerInterface|null,
  conventionAnnex: ConventionAnnex|null
}

interface openPartnerRelationLinkForm {
  show: boolean,
  relationLink: RelationLink|null
}

interface openAccountAddressForm {
  show: boolean,
  typeAddress: "postal" | "fiscal" | null
  defaultCountry: Country|null,
  defaultUserInfo: {
    firstname: string|null,
    lastname: string|null,
    title: string|null,
    type: string|null,
    socialReason: string|null,
  }
  defaultPostcode?: string|null
  defaultAddress?: string|null
  defaultAddress2?: string|null
  defaultAddress3?: string|null
  defaultCity?: City|null
}

interface openBankInformationForm {
  show: boolean,
  bankInformation: BankInformation|null
}

interface openNotePad {
  show: boolean
}

interface openProductBankInformationForm {
  show: boolean,
  bankInformation: ProductBankInformation|null
}

interface openAccountRelationForm {
  show: boolean,
  relation: Relation|null
}

interface openAccountBeneficialOwnerForm {
  show: boolean,
  beneficialOwner: BeneficialOwner|null
}

interface openAccountMoralLegalRepresentativeForm {
  show: boolean,
  moralLegalRepresentative: MoralLegalRepresentative|null
}

interface openAccountUndividedForm {
  show: boolean,
  undivided: Undivided|null
}

interface openPresubscriptionUploadForm {
  show: boolean
  documents: Document[]|null
  presubscriptionId: string|null
  type: string|null
}

interface openPartnerUploadForm {
  show: boolean,
  documents: DocumentToSend[]|null
  partnerId: string|null
  type: string|null
  conventionId: string|null
}

interface openProspectUploadForm {
  show: boolean,
  documents: DocumentProspect[]|null
  prospectId: string|null
  type: string|null
  ribId: string|null
  undividedId: string|null
  beneficialOwnerId: string|null
  moralLegalRepresentativeId: string|null
}

interface openProspectLegalRepresentativesForm {
  show: boolean,
  legalRepresentatives: ProspectLegalRepresentatives|null
}

interface openCustomerLegalRepresentativesForm {
  show: boolean,
  legalRepresentatives: CustomerLegalRepresentatives|null
}

interface openCustomerUploadForm {
  show: boolean,
  documents: DocumentCustomer[]|null
  personId: string|null
  type: string|null,
  ribId: string|null,
  undividedId: string|null,
  beneficialOwnerId: string|null,
  moralLegalRepresentativeId: string|null
}

interface openUploadForm {
  show: boolean,
  documents: DocumentInterface[]|null
  uuid: string|null
  type: string|null
}

interface openProductUploadForm {
  show: boolean,
  documents: DocumentProduct[]|null
  productId: string|null
  type: string|null,
  ribId: string|null
}

interface openLogoUploadForm {
  show: boolean,
  logo: Logo|null
  type: string|null
}

interface openChoiceInvestor {
  show: boolean,
  key: 'owner' | 'usufructuary' | 'transferor' | 'investor' | 'investorReceivingShares',
  dataListType: 'customer' | 'prospect' | 'all' | null
  propertyNature?: 'np' | 'usu'
}

interface IOpenChoiceSliceToGiveUp {
  show: boolean,
  prospectId: string|null
  productId: string|null
  shareCount: number|null
  choicePartSelectAll: boolean | null
  isForPledge?: boolean
  propertyType?: string
  usufructId?: string|null
}

interface openDatalistFilterPartner {
  show: boolean
  count: number
  filters: FilterPartnerInterface
}

interface openDatalistFilterCustomer {
  show: boolean
  count: number
  filters: FilterCustomerInterface
}

interface openDatalistFilterPledge {
  show: boolean
  count: number
  filters: FilterPledgeInterface
}

interface openDatalistFilterClient {
  show: boolean
  count: number
  filters: FilterCustomerInterface
}

interface openDatalistFilterPreSubscription {
  show: boolean
  count: number
  filters: FilterPreSubscriptionInterface
}

interface openDatalistFilterMovement {
  show: boolean
  count: number
  filters: FilterMovementInterface
}

interface openDatalistFilterPortfolio {
  show: boolean
  count: number
  filters: FilterPortfolioInterface
}

interface openDatalistFilterRecurrenceMandate {
  show: boolean
  count: number
  filters: FilterRecurrenceMandateInterface
}

interface openEditRecurrenceMandate {
  show: boolean
  mandateId: string
  readOnly: boolean
}

interface openDatalistFilterRecurrenceSession {
  show: boolean
  count: number
  filters: FilterRecurrenceSessionInterface
}

interface openDatalistFilterRecurrenceSessionMandate {
  show: boolean
  count: number
  filters: FilterRecurrenceSessionMandateInterface
}

interface openDatalistFilterRecurrence {
  show: boolean
  count: number
  filters: FilterRecurrenceInterface
}

interface openDatalistFilterDismenberment {
  show: boolean
  count: number
  filters: FilterDismenbermentInterface
}

interface openDatalistFilterProspect {
  show: boolean
  count: number
  filters: FilterProspectInterface
}

interface openDatalistFilterProduct {
  show: boolean
  count: number
  filters: FilterProductInterface
}

interface openDatalistFilterProductChild {
  show: boolean
  count: number
  filters: FilterProductInterface
}
interface openDatalistFilterUser {
  show: boolean
  count: number
  filters: FilterUserInterface
}

interface openDatalistFilterCommissioning {
  show: boolean
  count: number
  filters: FilterCommissioningInterface
}

interface openDatalistFilterCommissioningSession {
  show: boolean
  count: number
  filters: FilterCommissioningSessionInterface
}

interface openDatalistFilterDistributionSession {
  show: boolean
  count: number
  filters: FilterDistributionSessionInterface
}

interface openDatalistFilterDistributionSessionInvestor {
  show: boolean
  count: number
  filters: FilterSessionInvestorInterface
}

interface openChoiceProductChild {
  show: boolean
}

interface openChoicePartner {
  show: boolean
  key: string|null
  excludeHimSelf?: boolean
  partnerId?: string
  propertyNature?: 'np' | 'usu'
}
interface openPledgeOrganizationForm {
  show: boolean
  pledgeOrganization: PledgeOrganizationInterface|null
}
interface openPledgeForm {
  show: boolean
  pledge: PledgeInterface|null
}

interface openUserForm {
  show: boolean
  user: UserListInterface|null
}

interface openDocumentList {
  show: boolean,
  documentList: GedDocumentInterface[],
  gateway: DocumentGatewayInterface|null
  openCallback: ((fileId: number) => any) | null
}

interface openCommissionRelationForm {
  show: boolean
  level: string
  currentLevel?: string
}

interface openCityForm {
  show: boolean
  postcode?: string,
  label?: string,
  country: Country|null,
}

interface historyTransferDetails {
  show: boolean,
  item?: Transfer,
  partnerId?: string
}
interface openProductScaleForm {
  show: boolean,
  scale: Scale|null
  productId: string|null
  scales?: Scale[] | null
}
interface openProductScaleItemForm {
  show: boolean,
  scaleItem: ScaleItem|null
  scale: Scale|null
  productId: string|null
  readonly : boolean
}
interface openCommissionControlListForm {
  show: boolean,
  checkLists: ICommissionCheckListDetail[]|null
  commissioning: CommissioningSessionInterface|null
}

interface event {
  openMainNavigation: boolean
  openPartnerContactForm: openPartnerContactForm
  openPartnerAddAddressForm: openAddressForm
  openPartnerBeneficialBeneficiariesForm: openPartnerBeneficialBeneficiariesForm
  openPartnerConventionAnnexForm: openPartnerConventionAnnexForm
  openPartnerRelationLinkForm: openPartnerRelationLinkForm
  openAccountAddressForm: openAccountAddressForm
  openBankInformationForm: openBankInformationForm
  openNotePad: openNotePad
  openAccountRelationForm: openAccountRelationForm
  openAccountBeneficialOwnerForm: openAccountBeneficialOwnerForm
  openAccountUndividedForm: openAccountUndividedForm
  openAccountMoralLegalRepresentativeForm: openAccountMoralLegalRepresentativeForm
  openPartnerUploadForm: openPartnerUploadForm
  openPresubscriptionUploadForm: openPresubscriptionUploadForm
  openProductUploadForm: openProductUploadForm
  openProspectUploadForm: openProspectUploadForm
  openProspectLegalRepresentativesForm: openProspectLegalRepresentativesForm
  openCustomerLegalRepresentativesForm: openCustomerLegalRepresentativesForm
  openCustomerUploadForm: openCustomerUploadForm
  openLogoUploadForm: openLogoUploadForm
  openUploadForm: openUploadForm
  openChoiceInvestor: openChoiceInvestor
  openChoiceSliceToGiveUp: IOpenChoiceSliceToGiveUp
  openDatalistFilterPartner: openDatalistFilterPartner
  openDatalistFilterCustomer: openDatalistFilterCustomer
  openDatalistFilterPledge: openDatalistFilterPledge
  openDatalistFilterClient: openDatalistFilterClient
  openDatalistFilterPreSubscription: openDatalistFilterPreSubscription
  openDatalistFilterMovement: openDatalistFilterMovement
  openDatalistFilterPortfolio: openDatalistFilterPortfolio
  openEditRecurrenceMandate: openEditRecurrenceMandate
  openDatalistFilterRecurrenceMandate: openDatalistFilterRecurrenceMandate
  openDatalistFilterRecurrenceSession: openDatalistFilterRecurrenceSession
  openDatalistFilterRecurrenceSessionMandate: openDatalistFilterRecurrenceSessionMandate
  openDatalistFilterRecurrence: openDatalistFilterRecurrence
  openDatalistFilterDismenberment: openDatalistFilterDismenberment
  openDatalistFilterProspect: openDatalistFilterProspect
  openDatalistFilterProduct: openDatalistFilterProduct
  openDatalistFilterProductChild: openDatalistFilterProductChild
  openDatalistFilterUser: openDatalistFilterUser
  openDatalistFilterCommissioning: openDatalistFilterCommissioning
  openDatalistFilterCommissioningSession: openDatalistFilterCommissioningSession
  openDatalistFilterDistributionSession: openDatalistFilterDistributionSession
  openDatalistFilterDistributionSessionInvestor: openDatalistFilterDistributionSessionInvestor
  openChoiceProductChild: openChoiceProductChild
  openChoicePartner: openChoicePartner
  openProductBankInformationForm: openProductBankInformationForm
  openPledgeOrganizationForm: openPledgeOrganizationForm
  openPledgeForm: openPledgeForm
  openUserForm: openUserForm
  openDocumentList: openDocumentList
  openCommissionRelationForm: openCommissionRelationForm
  openCityForm: openCityForm
  historyTransferDetails: historyTransferDetails
  isLoading: boolean
  openProductScaleForm: openProductScaleForm
  openProductScaleItemForm: openProductScaleItemForm
  openCommissionControlListForm: openCommissionControlListForm
}

const initialState: event = {
  openMainNavigation: true,
  openPartnerContactForm:  {
    show: false,
    contact: null
  },
  openPartnerAddAddressForm: {
    show: false,
    address: null,
    defaultCountry: null
  },
  openPartnerBeneficialBeneficiariesForm: {
    show: false,
    beneficialBeneficiaries: null
  },
  openPartnerConventionAnnexForm: {
    show: false,
    partner: null,
    conventionAnnex: null
  },
  openPartnerRelationLinkForm: {
    show: false,
    relationLink: null
  },
  openAccountAddressForm: {
    show: false,
    typeAddress: null,
    defaultCountry: null,
    defaultUserInfo: {
      firstname: null,
      lastname: null,
      title: null,
      type: null,
      socialReason: null,
    }
  },
  openBankInformationForm: {
    show: false,
    bankInformation: null
  },
  openNotePad: {
    show: false,
  },
  openAccountRelationForm: {
    show: false,
    relation: null
  },
  openAccountBeneficialOwnerForm: {
    show: false,
    beneficialOwner: null
  },
  openAccountUndividedForm: {
    show: false,
    undivided: null
  },
  openAccountMoralLegalRepresentativeForm: {
    show: false,
    moralLegalRepresentative: null
  },
  openUploadForm: {
    show: false,
    documents: null,
    uuid: null,
    type: null
  },
  openPartnerUploadForm: {
    show: false,
    documents: null,
    partnerId: null,
    type: null,
    conventionId: null
  },
  openPresubscriptionUploadForm: {
    show: false,
    documents: null,
    presubscriptionId: null,
    type: null
  },
  openProductUploadForm: {
    show: false,
    documents: null,
    productId: null,
    type: null,
    ribId: null
  },
  openProspectUploadForm: {
    show: false,
    documents: null,
    prospectId: null,
    type: null,
    ribId: null,
    undividedId: null,
    beneficialOwnerId: null,
    moralLegalRepresentativeId: null
  },
  openProspectLegalRepresentativesForm: {
    show: false,
    legalRepresentatives: null
  },
  openCustomerLegalRepresentativesForm: {
    show: false,
    legalRepresentatives: null
  },
  openCustomerUploadForm: {
    show: false,
    documents: null,
    personId: null,
    type: null,
    ribId: null,
    undividedId: null,
    beneficialOwnerId: null,
    moralLegalRepresentativeId: null
  },
  openLogoUploadForm: {
    show: false,
    logo: null,
    type: null
  },
  openChoiceInvestor: {
    show: false,
    key: 'owner',
    dataListType: null
  },
  openChoiceSliceToGiveUp: {
    show: false,
    prospectId: null,
    productId: null,
    shareCount: null,
    choicePartSelectAll: null,
    isForPledge: false,
    usufructId: null
  },
  openDatalistFilterPartner: {
    show: false,
    count: 0,
    filters: {
      idPartner: '',
      keywords: '',
      socialReason: '',
      name: '',
      city: new City('', '', '', '', ''),
      product: {
        id: '',
        value: '',
        label: ''
      },
      type: [],
      platform: [],
      network: [],
      status: [],
      commissionLevel: ''
    }
  },
  openDatalistFilterCustomer: {
    show: false,
    count: 0,
    filters: {
      lastName: '',
      maidenName: '',
      keywords: '',
      birthDate: '',
      status: []
    }
  },
  openDatalistFilterPledge: {
    show: false,
    count: 0,
    filters: {
      product: '',
      dateStart: '',
      dateEnd: '',
      status: 'all',
    }
  },
  openDatalistFilterClient: {
    show: false,
    count: 0,
    filters: {
      lastName: '',
      maidenName: '',
      keywords: '',
      birthDate: '',
    }
  },
  openDatalistFilterPreSubscription: {
    show: false,
    count: 0,
    filters: {
      product: {
        id: '',
        value: '',
        label: '',
      },
      transactionType: '',
      status: [],
      statut: "",
      tags: [],
      propertyType: [],
      paymentMode: '',
      user: null,
      name: '',
      keywords: ''
    }
  },
  openDatalistFilterMovement: {
    show: false,
    count: 0,
    filters: {
      product: {
        id: '',
        value: '',
        label: '',
      },
      transactionType: '',
      status: [],
      tags: [],
      propertyType: [],
      paymentMode: '',
      user: null,
      investor_id: '',
      name: '',
      keywords: ''
    }
  },
  openDatalistFilterPortfolio: {
    show: false,
    count: 0,
    filters: {
      product: '',
      transaction_type: '',
      keywords: '',
      investor: '',
      start: '',
      end: '',
      property_type: '',
    }
  },
  openEditRecurrenceMandate: {
    show: false,
    mandateId: '',
    readOnly: false
  },
  openDatalistFilterRecurrenceMandate: {
    show: false,
    count: 0,
    filters: {
      product: {
        id: '',
        value: '',
        label: '',
      },
      status: [],
      periodicity: [],
      start: '',
      end: '',
      keyword: ''
    }
  },
  openDatalistFilterRecurrenceSession: {
    show: false,
    count: 0,
    filters: {
      keyword: '',
    }
  },
  openDatalistFilterRecurrenceSessionMandate: {
    show: false,
    count: 0,
    filters: {
      keyword: '',
      status: [],
    }
  },
  openDatalistFilterRecurrence: {
    show: false,
    count: 0,
    filters: {
      product: {
        id: '',
        value: '',
        label: '',
      },
      transactionType: '',
      status: [],
      tags: [],
      propertyType: [],
      paymentMode: '',
      user: null,
      name: '',
      keywords: ''
    }
  },
  openDatalistFilterDismenberment: {
    show: false,
    count: 0,
    filters: {
      product: {
        id: '',
        value: '',
        label: '',
      },
      transactionType: '',
      status: [],
      tags: [],
      propertyType: [],
      paymentMode: '',
      user: null,
      name: '',
      keywords: ''
    }
  },
  openDatalistFilterProspect: {
    show: false,
    count: 0,
    filters: {
      lastName: '',
      maidenName: '',
      keywords: '',
      birthDate: '',
      status: []
    }
  },
  openDatalistFilterProduct: {
    show: false,
    count: 0,
    filters: {
      name: '',
      keywords: '',
      type: '',
      subproduct: null,
      status: []
    }
  },
  openDatalistFilterProductChild: {
    show: false,
    count: 0,
    filters: {
      name: '',
      keywords: '',
      type: '',
      subproduct: true
    }
  },
  openDatalistFilterUser: {
    show: false,
    count: 0,
    filters: {
      firstName: '',
      lastName: '',
      keywords: '',
      roles: [],
      type: '',
    }
  },
  openDatalistFilterCommissioning: {
    show: false,
    count: 0,
    filters: {
      name: '',
      type: '',
      keywords: '',
    }
  },
  openDatalistFilterCommissioningSession: {
    show: false,
    count: 0,
    filters: {
      'products': '',
      'investors': '',
      'partners': '',
      'levels': '',
      'scales': '',
      'date': ''
    }
  },
  openDatalistFilterDistributionSession: {
    show: false,
    count: 0,
    filters: {
      keyword: ''
    }
  },
  openDatalistFilterDistributionSessionInvestor: {
    show: false,
    count: 0,
    filters: {
      keyword: ''
    }
  },
  openChoiceProductChild: {
    show: false
  },
  openChoicePartner: {
    show: false,
    key: null
  },
  openProductBankInformationForm: {
    show: false,
    bankInformation: null
  },
  openPledgeOrganizationForm: {
    show: false,
    pledgeOrganization: null
  },
  openPledgeForm: {
    show: false,
    pledge: null
  },
  openUserForm: {
    show: false,
    user: null
  },
  openDocumentList: {
    show: false,
    documentList: [],
    gateway: null,
    openCallback: null,
  },
  openCommissionRelationForm: {
    show: false,
    level: ""
  },
  openCityForm: {
    show: false,
    label: "",
    postcode: "",
    country: null
  },
  historyTransferDetails: {
    show: false
  },
  isLoading: false,
  openProductScaleForm: {
    show: false,
    scale: null,
    productId: null
  },
  openProductScaleItemForm: {
    show: false,
    scale: null,
    scaleItem: null,
    productId: null,
    readonly: false
  },
  openCommissionControlListForm: {
    show: false,
    checkLists: null,
    commissioning: null
  }
}

export const eventSlice = createSlice({
  name: 'event',
  initialState: initialState,
  reducers: {
    setOpenMainNavigationEvent: (state, action) => {
      state.openMainNavigation = action.payload
    },
    setOpenPartnerContactFormEvent: (state, action) => {
      state.openPartnerContactForm = action.payload
    },
    setOpenPartnerAddAddressEvent: (state, action) => {
      state.openPartnerAddAddressForm = action.payload
    },
    setOpenPartnerBeneficialBeneficiariesFormEvent: (state, action) => {
      state.openPartnerBeneficialBeneficiariesForm = action.payload
    },
    setOpenPartnerConventionAnnexFormEvent: (state, action) => {
      state.openPartnerConventionAnnexForm = action.payload
    },
    setOpenPartnerRelationLinkFormEvent: (state, action) => {
      state.openPartnerRelationLinkForm = action.payload
    },
    setOpenAccountAddressFormEvent: (state, action) => {
      state.openAccountAddressForm = action.payload
    },
    setOpenBankInformationFormEvent: (state, action) => {
      state.openBankInformationForm = action.payload
    },
    setOpenNotePadEvent: (state, action) => {
      state.openNotePad = action.payload
    },
    setOpenAccountRelationFormEvent: (state, action) => {
      state.openAccountRelationForm = action.payload
    },
    setOpenAccountBeneficialOwnerForm: (state, action) => {
      state.openAccountBeneficialOwnerForm = action.payload
    },
    setOpenAccountUndividedForm: (state, action) => {
      state.openAccountUndividedForm = action.payload
    },
    setOpenAccountMoralLegalRepresentativeForm: (state, action) => {
      state.openAccountMoralLegalRepresentativeForm = action.payload
    },
    setOpenUploadForm: (state, action) => {
      state.openUploadForm = action.payload
    },
    setOpenPartnerUploadForm: (state, action) => {
      state.openPartnerUploadForm = action.payload
    },
    setOpenPresubscriptionUploadForm: (state, action) => {
      state.openPresubscriptionUploadForm = action.payload
    },
    setOpenProspectUploadForm: (state, action) => {
      state.openProspectUploadForm = action.payload
    },
    setOpenProspectLegalRepresentativesForm: (state, action) => {
      state.openProspectLegalRepresentativesForm = action.payload
    },
    setOpenCustomerLegalRepresentativesForm: (state, action) => {
      state.openCustomerLegalRepresentativesForm = action.payload
    },
    setOpenCustomerUploadForm: (state, action) => {
      state.openCustomerUploadForm = action.payload
    },
    setOpenProductUploadForm: (state, action) => {
      state.openProductUploadForm = action.payload
    },
    setOpenLogoUploadForm: (state, action) => {
      state.openLogoUploadForm = action.payload
    },
    setOpenChoiceInvestorEvent: (state, action) => {
      state.openChoiceInvestor = action.payload
    },
    setOpenChoiceSliceToGiveUpEvent: (state, action) => {
      state.openChoiceSliceToGiveUp = action.payload
    },
    setOpenDatalistFilterPartner: (state, action) => {
      state.openDatalistFilterPartner = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterPartner.count = countTotal
    },
    setOpenDatalistFilterCustomer: (state, action) => {
      state.openDatalistFilterCustomer = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterCustomer.count = countTotal
    },
    setOpenDatalistFilterPledge: (state, action) => {
      state.openDatalistFilterPledge = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterPledge.count = countTotal
    },
    setOpenDatalistFilterClient: (state, action) => {
      state.openDatalistFilterClient = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterClient.count = countTotal
    },
    setOpenDatalistFilterPreSubscription: (state, action) => {
      state.openDatalistFilterPreSubscription = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterPreSubscription.count = countTotal
    },
    setOpenDatalistFilterMovement: (state, action) => {
      state.openDatalistFilterMovement = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterMovement.count = countTotal
    },
    setOpenDataListFilterPortfolio: (state, action) => {
      state.openDatalistFilterPortfolio = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterPortfolio.count = countTotal
    },
    setOpenDatalistFilterRecurrenceMandate: (state, action) => {
      state.openDatalistFilterRecurrenceMandate = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterRecurrenceMandate.count = countTotal
    },
    setOpenDatalistFilterRecurrenceSession: (state, action) => {
      state.openDatalistFilterRecurrenceSession = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterRecurrenceSession.count = countTotal
    },
    setOpenDatalistFilterRecurrenceSessionMandate: (state, action) => {
      state.openDatalistFilterRecurrenceSessionMandate = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterRecurrenceSessionMandate.count = countTotal
    },
    setOpenEditRecurrenceMandate: (state, action) => {
      state.openEditRecurrenceMandate = action.payload
    },
    setOpenDatalistFilterRecurrence: (state, action) => {
      state.openDatalistFilterRecurrence = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterRecurrence.count = countTotal
    },
    setOpenDatalistFilterDismenberment: (state, action) => {
      state.openDatalistFilterDismenberment = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterDismenberment.count = countTotal
    },
    setOpenDatalistFilterProspect: (state, action) => {
      state.openDatalistFilterProspect = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterProspect.count = countTotal
    },
    setOpenDatalistFilterProduct: (state, action) => {
      state.openDatalistFilterProduct = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterProduct.count = countTotal
    },
    setOpenDatalistFilterProductChild: (state, action) => {
      state.openDatalistFilterProductChild = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterProductChild.count = countTotal
    },
    setOpenDatalistFilterUser: (state, action) => {
      state.openDatalistFilterUser = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterUser.count = countTotal
    },
    setOpenDatalistFilterCommissioning: (state, action) => {
      state.openDatalistFilterCommissioning = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterCommissioning.count = countTotal
    },
    setOpenDatalistFilterCommissioningSession: (state, action) => {
      state.openDatalistFilterCommissioningSession = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterCommissioningSession.count = countTotal
    },
    setOpenDatalistFilterDistributionSession: (state, action) => {
      state.openDatalistFilterDistributionSession = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterDistributionSession.count = countTotal
    },
    setOpenDatalistFilterDistributionSessionInvestor: (state, action) => {
      state.openDatalistFilterDistributionSessionInvestor = action.payload

      let countTotal = 0
      Object.entries(action.payload.filters).map(item => {
        countTotal = (item[1] !== '') ? countTotal + 1 : countTotal
      })
      state.openDatalistFilterDistributionSessionInvestor.count = countTotal
    },
    setOpenChoiceProductChildEvent: (state, action) => {
      state.openChoiceProductChild = action.payload
    },
    setOpenChoicePartnerEvent: (state, action) => {
      state.openChoicePartner = action.payload
    },
    setOpenChoicePartner: (state, action) => {
      state.openChoicePartner = action.payload
    },
    setOpenProductBankInformation: (state, action) => {
      state.openProductBankInformationForm = action.payload
    },
    setOpenPledgeOrganizationForm: (state, action) => {
      state.openPledgeOrganizationForm = action.payload
    },
    setOpenPledgeForm: (state, action) => {
      state.openPledgeForm = action.payload
    },
    setOpenUserForm: (state, action) => {
      state.openUserForm = action.payload
    },
    setOpenDocumentList: (state, action) => {
      state.openDocumentList = action.payload
    },
    setOpenCommissionRelationForm: (state, action) => {
      state.openCommissionRelationForm = action.payload
    },
    setOpenCityForm: (state, action) => {
      state.openCityForm = action.payload
    },
    setOpenHistoryTransferDetails: (state, action) => {
      state.historyTransferDetails = action.payload
    },
    setIsLoading: (state, action) => {state.isLoading = action.payload},
    setOpenProductScaleForm: (state, action) => {state.openProductScaleForm = action.payload},
    setOpenProductScaleItemForm: (state, action) => {state.openProductScaleItemForm = action.payload},
    setOpenCommissionControlListForm: (state, action) => {
      state.openCommissionControlListForm = action.payload
    },
  }
})

export const {
  setOpenMainNavigationEvent,
  setOpenPartnerContactFormEvent,
  setOpenPartnerAddAddressEvent,
  setOpenPartnerBeneficialBeneficiariesFormEvent,
  setOpenPartnerConventionAnnexFormEvent,
  setOpenPartnerRelationLinkFormEvent,
  setOpenAccountAddressFormEvent,
  setOpenBankInformationFormEvent,
  setOpenNotePadEvent,
  setOpenAccountRelationFormEvent,
  setOpenAccountBeneficialOwnerForm,
  setOpenAccountUndividedForm,
  setOpenAccountMoralLegalRepresentativeForm,
  setOpenPartnerUploadForm,
  setOpenPresubscriptionUploadForm,
  setOpenProspectUploadForm,
  setOpenProspectLegalRepresentativesForm,
  setOpenCustomerLegalRepresentativesForm,
  setOpenCustomerUploadForm,
  setOpenProductUploadForm,
  setOpenLogoUploadForm,
  setOpenUploadForm,
  setOpenChoiceInvestorEvent,
  setOpenChoiceSliceToGiveUpEvent,
  setOpenDatalistFilterPartner,
  setOpenDatalistFilterCustomer,
  setOpenDatalistFilterPledge,
  setOpenDatalistFilterPreSubscription,
  setOpenDatalistFilterMovement,
  setOpenDataListFilterPortfolio,
  setOpenDatalistFilterRecurrenceMandate,
  setOpenDatalistFilterRecurrenceSession,
  setOpenDatalistFilterRecurrenceSessionMandate,
  setOpenEditRecurrenceMandate,
  setOpenDatalistFilterRecurrence,
  setOpenDatalistFilterDismenberment,
  setOpenDatalistFilterProspect,
  setOpenDatalistFilterProduct,
  setOpenDatalistFilterProductChild,
  setOpenDatalistFilterUser,
  setOpenDatalistFilterCommissioning,
  setOpenDatalistFilterCommissioningSession,
  setOpenDatalistFilterDistributionSession,
  setOpenDatalistFilterDistributionSessionInvestor,
  setOpenChoiceProductChildEvent,
  setOpenChoicePartnerEvent,
  setOpenChoicePartner,
  setOpenProductBankInformation,
  setOpenDatalistFilterClient,
  setOpenPledgeOrganizationForm,
  setOpenPledgeForm,
  setOpenUserForm,
  setOpenDocumentList,
  setOpenCommissionRelationForm,
  setOpenCityForm,
  setOpenHistoryTransferDetails,
  setIsLoading,
  setOpenProductScaleForm,
  setOpenProductScaleItemForm,
  setOpenCommissionControlListForm
} = eventSlice.actions

export default eventSlice.reducer

export type {openUploadForm, IOpenChoiceSliceToGiveUp}
