import {Presenter} from '../Presenter'
import { SessionListInterface } from '../../domain/Distribution/SessionList'

class SessionListPresenter extends Presenter {

  private useCase: {
    retrieveSessionList: { data: SessionListInterface[] | null, nb_element: number }
  }

  constructor(retrieveSessionList: any) {
    super({
      viewModel: {
        title: 'distribution.session.search',
        heading: [
          {name: 'distribution.session.table.heading.action'},
          {name: 'distribution.session.table.heading.products'},
          {name: 'distribution.session.table.heading.session-number'},
          {name: 'distribution.session.table.heading.type'},
          {name: 'distribution.session.table.heading.status'},
        ],
        data: [],
        filtersShortcut: [
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keyword', 'type': 'input'},
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    })

    this.useCase = {
      retrieveSessionList,
    }
  }

  async load() {
    try {
      this._setSessions(await this.useCase.retrieveSessionList)
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setSessions(distributions: { data: SessionListInterface[] | null, nb_element: number }) {
    this.update({
      data: distributions.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': distributions.nb_element,
      }
    })
  }
}

export default SessionListPresenter
