import { PersonInterface } from '../../../../../../../domain/Customer/Person'
import {IFormInputGeneralInformationPhysicalPerson} from '../type'
import {DocumentType} from '../../../../../../../domain/Document/DocumentInterface'
import DocumentUtils from '../../../../../util/DocumentUtils'

const updateCustomerPersons = (
  persons: PersonInterface[] | null,
  data: IFormInputGeneralInformationPhysicalPerson,
  documentArray: DocumentType,
  type: string
): PersonInterface[] | null => {
  return persons ? persons.map((person, index) => {
    if (person?.id) {

      const documentUtils = new DocumentUtils()
      const updatedPerson: PersonInterface = {
        ...person,
        title: data.persons[index].title,
        type: type,
        ppe: data.persons[index].ppe,
        ppeCategory: data.persons[index].ppeCategory,
        lastname: data.persons[index].lastname,
        firstname: data.persons[index].firstname,
        maidenName: data.persons[index].maidenName,
        birthdate: data.persons[index].birthDate,
        birthCity: data.persons[index].birthCity,
        birthCountry: data.persons[index].birthCountry,
        nationality: data.persons[index].nationality,
        familySituation: data.persons[index].familySituation,
        matrimonialRegime: data.persons[index].matrimonialRegime,
        legalCapacity: data.persons[index].legalCapacity,
        email: data.persons[index].email,
        phone: data.persons[index].phone,
        documents: data.documents && data.documents[person.id]
          ? documentUtils.getDocumentsForPerson(data.documents[person.id], documentArray, person.id)
          : person.documents,
        extranet: data.customerExtranetRadio === "1",
        extranetCreatedAt: data.persons[index].extranetCreatedAt,
        optinMarketing: data.persons[index].optinMarketing,
        optinDividend: data.persons[index].optinDividend,
        optinFiscality: data.persons[index].optinFiscality,
        optinGeneralAssembly: data.persons[index].optinGeneralAssembly,
        optinInvitation: data.persons[index].optinInvitation,
        address: data.persons[index].address,
        fiscality: {
          id: data.persons[index].fiscality?.id ?? null,
          createdAt: data.persons[index].fiscality?.createdAt ?? null,
          updatedAt: data.persons[index].fiscality?.updatedAt ?? null,
          deletedAt: data.persons[index].fiscality?.deletedAt ?? null,
          paymentMethod: data.persons[index].fiscality?.paymentMethod ?? null,
          taxResidency: data.persons[index].fiscality?.taxResidency ?? null,
          rate: data.persons[index].fiscality?.rate ?? null,
          businessTax: data.persons[index].fiscality?.businessTax ?? false,
          incomeTax: data.persons[index].fiscalityIncomeTax === "1",
          subjectIfi: data.persons[index].fiscalitySubjectIfi === "1",
          ifi: data.persons[index].fiscality?.ifi ?? null,
          lei: data.persons[index].fiscality?.lei ?? null,
          nif: data.persons[index].fiscality?.nif ?? null
        }
      }

      return updatedPerson
    }

    return person
  }) : null
}

export default updateCustomerPersons
