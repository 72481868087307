import {IFormInputGeneralInformationPhysicalPerson} from '../type'
import {CustomerInterface} from '../../../../../../../domain/Customer/Customer'

export const setCustomerValues = (
  customer: Partial<CustomerInterface>,
  methods: any
) => {
  const customerFields = {
    customerCode: customer.customerCode,
    externalDepositaryCode: customer.externalDepositaryCode,
    externalCode: customer.externalCode,
    recoveryCode: customer.recoveryCode,
    statusCreatedAt: customer.statusCreatedAt,
    status: customer.status,
    exitedAt: customer.exitedAt,
    blockedAt: customer.blockedAt,
    kycCategoryValue: customer.kycCategoryValue,
    kycCategoryDate: customer.kycCategoryDate,
    kycLabft: customer.kycLabft,
    kycLabftDate: customer.kycLabftDate,
    kycProfession: customer.kycProfession,
    kycFatca: customer.kycFatca,
    groupRelation: customer.groupRelation,
    kycCategoryValueSecond: customer.kycCategoryValueSecond,
    kycCategoryDateSecond: customer.kycCategoryDateSecond,
    kycLabftSecond: customer.kycLabftSecond,
    kycLabftDateSecond: customer.kycLabftDateSecond,
    kycProfessionSecond: customer.kycProfessionSecond,
    kycFatcaSecond: customer.kycFatcaSecond,
    groupRelationSecond: customer.groupRelationSecond,
  }

  Object.entries(customerFields).forEach(([key, value]) => {
    methods.setValue(key as keyof IFormInputGeneralInformationPhysicalPerson, value)
  })
}

export const setPersonsValues = (
  persons: CustomerInterface['persons'],
  methods: any
) => {
  if (!persons) {
    return
  }

  persons.forEach((person, index) => {
    if (!person.id) {
      return
    }

    if (index === 0) {
      methods.setValue("customerExtranetRadio", person.extranet ? "1" : "0")
    }

    const personFields: Partial<IFormInputGeneralInformationPhysicalPerson['persons'][0]> = {
      id: person.id,
      createdAt: person.createdAt,
      updatedAt: person.updatedAt,
      deletedAt: person.deletedAt,
      title: person.title,
      firstname: person.firstname,
      lastname: person.lastname,
      maidenName: person.maidenName,
      type: person.type,
      gerant: person.gerant ? "1" : "0",
      birthDate: person.birthdate,
      birthCity: person.birthCity,
      birthCountry: person.birthCountry,
      nationality: person.nationality,
      ppe: person.ppe ?? false,
      ppeCategory: person.ppeCategory,
      legalCapacity: person.legalCapacity,
      familySituation: person.familySituation,
      matrimonialRegime: person.matrimonialRegime,
      email: person.email,
      phone: person.phone,
      extranet: person.extranet ?? false,
      extranetCreatedAt: person.extranetCreatedAt,
      optinMarketing: person.optinMarketing,
      optinDividend: person.optinDividend,
      optinFiscality: person.optinFiscality,
      optinGeneralAssembly: person.optinGeneralAssembly,
      optinInvitation: person.optinInvitation,
      address: person.address,
      fiscality: person.fiscality,
    }

    // Use Object.entries to set the values
    Object.entries(personFields).forEach(([key, value]) => {
      methods.setValue(`persons.${index}.${key}` as keyof IFormInputGeneralInformationPhysicalPerson['persons'][0], value)
    })

    // Handle fiscality if present
    if (person.fiscality) {
      methods.setValue(`persons.${index}.fiscalityIncomeTax`, person.fiscality.incomeTax ? "1" : "0")
      methods.setValue(`persons.${index}.fiscalitySubjectIfi`, person.fiscality.subjectIfi ? "1" : "0")
      methods.setValue(`persons.${index}.fiscality.taxResidency`, person.fiscality.taxResidency ?? "france")
    }
  })
}
