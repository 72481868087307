import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import {ProductListInterface, SessionInterface, TSessionProducts} from '../../../../../../domain/Distribution'
import { ReferentielInterface } from '../../../../../../domain/Referentiel/ReferentielInterface'
import { useAppSelector } from '../../../../../store/hook'
import {AmountDistributedInterface} from '../../../../../../domain/Distribution/AmountDistributed'

type TProps = {
  product: ProductListInterface
  session?: SessionInterface|null
  isLectureMode?: boolean
}

const ProductItem: FunctionComponent<TProps> = ({ product, session, isLectureMode }) => {
  const { t } = useTranslation()
  const referential: ReferentielInterface | null = useAppSelector(({ referential }) => referential.referential)
  const { getValues, setValue, control } = useFormContext()
  const [isChecked, setIsChecked] = useState<boolean>(false)

  useEffect(() => {
    const currentProducts = getValues('sessionProducts') || {}
    if (currentProducts[product.id] && currentProducts[product.id].productId === product.id) {
      setIsChecked(true)
    }
  }, [getValues, product.id])

  const handleProductCheck = (product: ProductListInterface, isChecked: boolean) => {
    setIsChecked(isChecked)

    const currentProducts = getValues('sessionProducts') || {}

    if (isChecked) {
      setValue(`sessionProducts.${product.id}`, {
        productId: product.id,
        financialAmount: getValues(`sessionProducts.${product.id}.financialAmount`) || 0,
        propertyAmount: getValues(`sessionProducts.${product.id}.propertyAmount`) || 0,
      })
    } else {
      const updatedProducts = { ...currentProducts }
      delete updatedProducts[product.id]
      setValue('sessionProducts', updatedProducts)
    }
  }

  const handleInputChange = (
    field: 'financialAmount' | 'propertyAmount',
    value: number
  ) => {
    setValue(`sessionProducts.${product.id}.${field}`, value)
  }

  return (
    <tr>
      <td style={{ minWidth: '1px' }}>
        <input
          name={`sessionProducts.${product.id}.productId`}
          type="checkbox"
          checked={isChecked}
          onChange={(e) => handleProductCheck(product, e.target.checked)}
          disabled={isLectureMode}
        />
      </td>
      <td>{product.label}</td>
      <td>{referential?.product.periodicity.find(option => option.value === product.periodicity)?.label}</td>
      <td>{referential?.product.distribution_term.find(option => option.value === product.term)?.label}</td>
      <td>{product.ribTitle}</td>
      <td>
        <Controller
          name={`sessionProducts.${product.id}.propertyAmount`}
          control={control}
          render={({ field }) => (
            <input
              {...field}
              type="number"
              disabled={!isChecked || isLectureMode}
              onChange={(e) => {
                const value = parseFloat(e.target.value) || 0;
                field.onChange(value);
                handleInputChange('propertyAmount', value);
              }}
            />
          )}
        />
      </td>
      <td>
        <Controller
          name={`sessionProducts.${product.id}.financialAmount`}
          control={control}
          render={({ field }) => (
            <input
              {...field}
              type="number"
              disabled={!isChecked || isLectureMode}
              onChange={(e) => {
                const value = parseFloat(e.target.value) || 0;
                field.onChange(value);
                handleInputChange('financialAmount', value);
              }}
            />
          )}
        />
      </td>
      <td>
        {session?.amounts.map((amountDistributed: AmountDistributedInterface) => {
          if (amountDistributed.product.id === product.id) {
            return amountDistributed.totalBrut
          }
        })}
      </td>
      <td>
        {session?.amounts.map((amountDistributed: AmountDistributedInterface) => {
          if (amountDistributed.product.id === product.id) {
            return amountDistributed.nbShares
          }
        })}
      </td>
      <td>
        {session?.amounts.map((amountDistributed: AmountDistributedInterface) => {
          if (amountDistributed.product.id === product.id) {
            return amountDistributed.financialAmountPerShare
          }
        })}
      </td>
      <td>
        {session?.amounts.map((amountDistributed: AmountDistributedInterface) => {
          if (amountDistributed.product.id === product.id) {
            return amountDistributed.propertyAmountPerShare
          }
        })}
      </td>
    </tr>
  )
}

export default ProductItem
