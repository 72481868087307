import SessionGatewayInterface from '../../../domain/Distribution/SessionGatewayInterface';
import { SessionListInterface } from '../../../domain/Distribution/SessionList';
import {ListRequestInterface} from './ListRequest'


export default class ListUseCase
{
  public sessionGateway

  constructor(sessionGateway: SessionGatewayInterface) {
    this.sessionGateway = sessionGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal, sort?: string, order?: string): Promise<{data: SessionListInterface[]|null, nb_element: number}|null> {
    return await this.sessionGateway.getList(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, signal, sort, order).then(response => response)
  }
}
